<template>
  <div :class="classes">
    <b>Terminal</b>
    <p>{{ msg.message }} </p>
  </div>
  <a v-if="show_pay" href="" class="button call-to-action" @click.prevent.stop="pay">Kartenzahlung</a>
</template>

<style lang="scss">

.summary {
  .call-to-action {
    display: none;
  }
}

.terminal-control {
  position: fixed;
  right: 100px;
  top: 10px;
  

  b {
    font-size: 20px;
  }

  p {
    background-color: black;
    box-sizing: border-box;
    border-width: 2px;
    border-radius: 4px;
    border-style: solid;
    border-color: transparent;
    color: white;
    font-weight: bold;
    width: 300px;
    padding: 10px 0px;
  }
  &.neutral {
    p {
      border-color: #ddd;
      color: #ddd;
    }
  }
  &.success {
    p {
      border-color: #0c0;
      color: #0c0;
    }
  }
  &.error {
    p {
      border-color: #c00;
      color: #c00;
    }
  }

}

</style>

<script>

import ky from 'ky'

var config = {
  simulated: process.env.VUE_APP_STRIPE_TERMINAL_SIMULATE == "1",
  location: process.env.VUE_APP_TERMINAL_LOCATION
}

var terminal = null

function connectReaderHandler() {
  terminal.msg.status = 'neutral'
  terminal.msg.message = 'Richte Terminal ein...'
  terminal.discoverReaders(config).then(discoverResult => {
    if (discoverResult.error) {
      terminal.msg.status = "error"
      terminal.msg.message = "Die Verbindung mit dem örtlichen Netzwerk ist fehlgeschlagen"
      throw discoverResult.error
    }
    if (discoverResult.discoveredReaders.length === 0) {
      terminal.msg.status = "error"
      terminal.msg.message = "Terminal konnte im örtlichen Netzwerk nicht gefunden werden"
      throw discoverResult.error
    }

    var selectedReader = discoverResult.discoveredReaders[0] // first

    terminal.connectReader(selectedReader).then(connectResult => {
      if (connectResult.error) {
        terminal.msg.status = "error"
        terminal.msg.message = "Terminal wurde im örtlichen Netzwerk gefunden aber die Verbindung konnte nicht hergestellt werden"
        throw connectResult.error
      }
      terminal.msg.status = "success"
      terminal.msg.message = "Verbunden"
    })
  })
}

function unexpectedDisconnect() {
  // You might want to display UI to notify the user and start re-discovering readers
  console.log("unexpected disconnect")
}

export default {
  name: 'Terminal',
  props: {
    dish_id: Number
  },
  data () {
    return {
      msg: {"message": "Warte auf Terminal-Interface von Stripe...", "status": "neutral"},
    }
  },
  methods: {
    pay() {
      var pay = () => {
        terminal.msg.status = 'neutral'
        terminal.msg.message = "Leite Zahlung ein..."
        return ky.post(process.env.VUE_APP_API_URL + '/stripe/terminal-cnv8wxtchaz5zhok/' + this.$route.params.venue, {
          headers: {
            'session-token': localStorage.getItem('session token')
          }
        }).json().then(clientSecret => {
          terminal.msg.message = "Warte auf Karte..."
          return terminal.collectPaymentMethod(clientSecret).then(result => {
            if (result.error) {
              terminal.msg.status = "error"
              terminal.msg.message = 'Karte konnte nicht ausgelesen werden'
              throw result.error
            }
            return result.paymentIntent
          })
        }).then(paymentIntent => {
          terminal.msg.message = "Reserviere Betrag..."
          return terminal.processPayment(paymentIntent).then(result => {
            if (result.error) {
              terminal.msg.status = "error"
              terminal.msg.message = 'Zahlung konnte nicht verarbeitet werden'
              throw result.error
            }
            return result.paymentIntent
          })
        }).then(paymentIntent => {
          terminal.msg.message = "Buche..."
          return ky.put(process.env.VUE_APP_API_URL + '/stripe/terminal-cnv8wxtchaz5zhok/' + this.$route.params.venue, {
            headers: {
              'session-token': localStorage.getItem('session token')
            },
            json: paymentIntent
          }).json().then(() => {
            terminal.msg.status = "success"
            terminal.msg.message = "Zahlung Erfolgreich"
          })
        })
      }
      pay().then(() => {
        this.$store.commit('order', {})
        this.$store.commit('desire', {})
      })
    }
  },
  computed: {
    classes () {
      var c = {'terminal-control': true}
      c[this.msg.status] = true
      return c
    },
    show_pay() {
      return this.$route.name == 'Summary' && Object.entries(this.$store.state.order).length > 0
    }
  },
  mounted () {
    var script = document.createElement('script')
    script.setAttribute('src', 'https://js.stripe.com/terminal/v1/')
    document.head.appendChild(script)

    var fetchConnectionToken = () => {
      return ky.get(process.env.VUE_APP_API_URL + '/stripe/terminal-cnv8wxtchaz5zhok/' + this.$route.params.venue, {
        headers: {
          'session-token': localStorage.getItem('session token')
        }
      }).json().then(response => {
        return response.secret
      })
    }

    var i = setInterval(() => {
      if ( ! window.StripeTerminal) return
      terminal = window.StripeTerminal.create({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: unexpectedDisconnect,
      })
      terminal.msg = this.msg
      connectReaderHandler()
      clearInterval(i)
    }, 500)
  }
}

</script>

